/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC500M
 * 화면 설명: 이벤트/세미나소개(후보자모집 URL 생성 및 연계 화면)
 * 작 성 일: 2022.06.30
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container title="이벤트/세미나소개 개인코드" :show-title="true" type="subpage">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_10" direction="column" class="ns-icon-area">
        <ur-box-container alignV="start" componentid="ur_box_container_01" direction="column" class="icon-box">
          <div class="icon-img">
            <img src="/images/icon_code.svg">
          </div>
          <p class="icon-txt black">
            <span>{{ userNm }}</span> {{ srvcMngScCd }}님의 링크입니다.<br><br>
            삼성생명 이벤트와 세미나<br>소개를 위한 페이지링크 입니다.
          </p>

          <div class="link-box" @click="fn_CopyUrl(url)">
            <span>{{ url }}</span>
          </div>
          <p class="icon-txt gray special">
            ※ 링크 전달 시에는 사전 안내 및 후보자 의사를 확인 후 활용해주세요
          </p>
          <p class="icon-txt gray special">
            ※ 안내 내용에 대해서 민원이 발생하지 않도록 고객 안내가 잘 이루어지도록 해주세요
          </p>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_GoHome">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Check">문자보내기</mo-button>
          </div>
        </ur-box-container>

        <!-- Toast -->
        <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
        <div class="ns-height40"></div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPSA203P from '@/ui/sa/MSPSA203P.vue'
import MSPCM128P from '@/ui/cm/MSPCM128P.vue'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  name: 'MSPRC500M',
  screenId: 'MSPRC500M',
  mixins:[Screen],
  components: {
    MSPSA203P,
    MSPCM128P,
    MspBottomSelect
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data() {
    return {
      url: '',
      link: 'https://family.samsunglife.com/mc/link.do?pl=',

      userInfo: {}, // 사용자 정보
      userId: '', // 사번
      userNm: '', // 이름
      // 직책
      srvcMngScCd: '', // 서비스관리구분코드
      chnlScCd: '', // 채널구분코드

      todayMorn: '', // 금일오전체크
      todayEven: '', // 금일오후체크
      todayTime: '', // 금일시간

      toastMsg: '', // toast 문구
      txtMsg: '', // 문자내용
      custNm: '',
      chnlCustId: '',
      celno: '',
      custListObj: [],
      popData: {}
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {},

  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created() {
    this.fn_Init()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC500M')
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 사용자 정보를 조회한다.
    ******************************************************************************/
    fn_Init () {
      let lv_Vm = this

      // 사용자 정보
      lv_Vm.userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo

      lv_Vm.userId = lv_Vm.userInfo.userId // 사번
      lv_Vm.userNm = lv_Vm.userInfo.userNm // 이름

      // 직책 어떤거???
      lv_Vm.srvcMngScCd = lv_Vm.userInfo.srvcMngScCd // 서비스관리구분코드
      lv_Vm.chnlScCd = lv_Vm.userInfo.chnlScCd // 채널구분코드

      // 서버시간 조회(오늘 날짜)
      // 오전 8시 ~ 오후 9시 까지만 링크 공유 가능
      lv_Vm.$commonUtil.getServerTime().then(function (response) {
        lv_Vm.todayMorn = moment(response).format('YYYYMMDD') + '080000' // 금일 오전 체크
        lv_Vm.todayEven = moment(response).format('YYYYMMDD') + '210000' // 금일 오후 체크
      })

      // url 세팅
      this.fn_GetUserIdEncr()
      
    },
    /******************************************************************************
    * Function명 : fn_GetUserIdEncr
    * 설명       : USER ID의 암호화 정보를 조회함.
    ******************************************************************************/
    fn_GetUserIdEncr () {
      let lv_Vm = this
      let trnstId = 'txTSSRC70S4'
      let pParams = {}

      lv_Vm.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (!_.isEmpty(response.body)) {
            let resItm = response.body
              if (!lv_Vm.$bizUtil.isEmpty(resItm.traineNm)) {
                if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
                  lv_Vm.url = 'https://vfamily.samsunglife.com/mc/link.do?pl=' + resItm.traineNm
                } else {
                  lv_Vm.url = lv_Vm.link + + resItm.traineNm
                }
              }
          }
        })
        .catch(function (error) {
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          if (!_.isEmpty(error.message)) {
            lv_Vm.getStore('confirm').dispatch('ADD', error.message)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.') // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
          }
        })
    },
    /******************************************************************************
    * Function명 : fn_CopyUrl
    * 설명       : URL 클립보드 복사
    ******************************************************************************/
    fn_CopyUrl (url) {
      let lv_Vm = this

      // 금일 21시 ~ 익일 08시 사이에는 링크 공유 불가능
      lv_Vm.$commonUtil.getServerTime().then(function (response) {
        lv_Vm.todayTime = moment(response).format('YYYYMMDDHHmmss') // 금일시간

        let nowTime = Number(lv_Vm.todayTime) // 현재시간
        let chkTodayMorn = Number(lv_Vm.todayMorn) // 금일 08시
        let chkTodayEven = Number(lv_Vm.todayEven) // 금일 21시

        if (nowTime < chkTodayMorn || nowTime > chkTodayEven) {
          // 에러 메세지
          lv_Vm.getStore('confirm').dispatch('ADD', '오후 9시 ~ 오전 8시에는 링크 공유가 불가합니다. 오전 8시 이후 시도해주세요.')
        } else {
          let dummy = document.createElement('input')

          document.body.appendChild(dummy)
          dummy.value = url
          dummy.select()
          document.execCommand('copy')
          document.body.removeChild(dummy)

          lv_Vm.toastMsg = '복사 완료'
          lv_Vm.$refs.snackbar.show({ text: lv_Vm.toastMsg })
        }
      })
    },

    /******************************************************************************
    * Function명 : fn_GoHome
    * 설명       : 취소 버튼 누를 시 메인 홈 이동
    ******************************************************************************/
    fn_GoHome () {
      this.$router.push({name: 'MSPBC002M'})
    },

    /******************************************************************************
    * Function명 : fn_Check()
    * 설명       : 문자 보내기 전 조건 체크
    ******************************************************************************/
    fn_Check () {
      let lv_Vm = this

      // 금일 21시 ~ 익일 08시 사이에는 링크 공유 불가능
      lv_Vm.$commonUtil.getServerTime().then(function (response) {
        lv_Vm.todayTime = moment(response).format('YYYYMMDDHHmmss') // 금일시간

        let nowTime = Number(lv_Vm.todayTime) // 현재시간
        let chkTodayMorn = Number(lv_Vm.todayMorn) // 금일 08시
        let chkTodayEven = Number(lv_Vm.todayEven) // 금일 21시

        if (nowTime < chkTodayMorn || nowTime > chkTodayEven) {
          // 에러 메세지
          lv_Vm.getStore('confirm').dispatch('ADD', '오후 9시 ~ 오전 8시에는 링크 공유가 불가합니다. 오전 8시 이후 시도해주세요.')
        } else {
          lv_Vm.fn_SearchCust() // 고객검색 함수로 이동
        }
      })

    },
    /******************************************************************************
    * Function명 : fn_SearchCust
    * 설명       : 고객검색창띄우기
    ******************************************************************************/
   fn_SearchCust () {
      let lv_Vm = this

      lv_Vm.popData = lv_Vm.$bottomModal.open(MSPSA203P,{
        properties: {
          paramData: [] // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          popupCloseMSPSA203P: (pData) => {
            if (pData !== '') {
              lv_Vm.custListObj = [{targetType: 's', parentsViewId: 'TSSRC500M', custList: pData}]
              lv_Vm.fn_OpenPopSmsSendList('MSPCM128P')
            } else {
              lv_Vm.$bottomModal.close(lv_Vm.popData)
            }
          }
        }
      })
   },
   /*********************************************************
    * Function명: fn_OpenPopCustList
    * 설명: 수신고객목록 팝업오픈
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OpenPopSmsSendList(popup) {
      let lv_Vm = this
      let popData1 = lv_Vm.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: lv_Vm.custListObj, // param셋팅
          isOnlyCelno: true
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          confirmPopupCelno: (pData) => {
            lv_Vm.celno = pData
            lv_Vm.$bottomModal.close(this.popData)
            lv_Vm.$bottomModal.close(popData1)
            lv_Vm.fn_MakeMessage()
          },
          onPopupClose: () => {
            lv_Vm.$bottomModal.close(popData1)
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_MakeMessage
    * 설명       : 메시지구성하기
    ******************************************************************************/
    fn_MakeMessage () {
      this.txtMsg = '광고)[삼성생명] 이벤트/세미나 소개 \n\n '
                    + '안녕하세요 고객님. \n\n '
                    + '삼성생명에서 진행하는 이벤트와 세미나를 \n '
                    + '소개해 드리오니, 관심있는 고객님들의 \n '
                    + '많은 참여 부탁드리겠습니다. \n\n\n '
                    + '자세한 내용은 아래 링크를 통해 확인해 주세요. \n\n\n '
                     + this.url + ' \n\n '
                     + '♣삼성생명 콜센터 1588-3114 \n ' 
                     + ' 무료수신거부전화 080-331-3114 \n ' 
                     + ' [준법감시필 22-1488]'
                
      this.fn_SendSMS() 
    },
    /******************************************************************************
    * Function명 : fn_SendSMS
    * 설명       : 문자 보내기
    ******************************************************************************/
    fn_SendSMS () {
      let lv_Vm = this
      window.fdpbridge.exec('SMSPlugin', {number: lv_Vm.celno, message: lv_Vm.txtMsg}, () => {}, () => {})
    }
  }
}
</script>
